import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import moment from "moment-timezone";
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import GoogleMapComponent from "../../components/map/GoogleMapComponent";
import { db } from "../../firebase";
import { database } from "../../firebase";
import { ref, onValue, set, serverTimestamp } from 'firebase/database';
import {
  collection,
  query,
  where,
  onSnapshot,
  Timestamp,
  getDocs,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  orderBy
} from "firebase/firestore";
import axios from "axios";
import EMS from "../../components/images/EMS.png";
import FIRE from "../../components/images/FIRE.png";
import HP from "../../components/images/HP.png";
import hydrantdata from "../../Assets/suffolkhydrants.json";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import notificationSound from "../../sounds/PSAPTone.wav";
import notificationMp3 from "../../Assets/notification.mp3";
import cabinChime from "../../Assets/cabinchime.mp3";
import { FaAmbulance, FaFireAlt, FaSyncAlt, FaTimes, FaExclamationTriangle, FaArrowRight, FaArrowLeft, FaEyeSlash, FaEye } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";

const Home = () => {
  const { userData } = useContext(AuthContext);
  const location = useLocation();
  const toastId = useRef(null);
  const processedDocIds = useRef(new Set());
  const [alarmData, setAlarmData] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [prevFresComments, setPrevFresComments] = useState(null);
  const [mapLocation, setMapLocation] = useState(null);
  const [lastUpdatedText, setLastUpdatedText] = useState("");
  const [callins, setCallins] = useState([]);
  const [hydrantLocations, setHydrantLocations] = useState([]);
  const [vehicleUnits, setVehicleUnits] = useState([]);
  const [callerLocation, setCallerLocation] = useState(null);
  const [isCloseAlarmModalOpen, setIsCloseAlarmModalOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [PSAPTimes, setPSAPTimes] = useState([]);
  const [selectedCall, setSelectedCall] = useState(null);
  const [preplanData, setPreplanData] = useState(null);
  const [isCallInsDetached, setIsCallInsDetached] = useState(false);
  const [isCallInsHidden, setIsCallInsHidden] = useState(false);
  const [agencyLogo, setAgencyLogo] = useState(null);
  const notificationAudio = useRef(new Audio(notificationMp3));
  const cabinChimeAudio = useRef(new Audio(cabinChime));
  const prevPSAPTimesLength = useRef(0);

  useEffect(() => {
    cabinChimeAudio.current.load();
  }, []);

  useEffect(() => {
    setHydrantLocations(hydrantdata);
  }, []);

  const extractCallerCoordinates = useCallback((eventComments) => {
    // Define a regular expression pattern to match coordinates
    const regex = /-?\d+\.\d+\s+-?\d+\.\d+/;
    
    if (!eventComments) {
      return;
    }

    // Find matches in the EventComments text
    const matches = eventComments.match(regex);
    
    if (matches && matches.length > 0) {
      // The first match contains the coordinates
      const coordinates = matches[0];
    
      // Parse the coordinates
      const [longitudeStr, latitudeStr] = coordinates.split(' ');
      const latitude = parseFloat(latitudeStr);
      const longitude = parseFloat(longitudeStr);
      
      if (!isNaN(latitude) && !isNaN(longitude)) {
        // Save the coordinates in state
        setCallerLocation({ latitude, longitude });
      } else {
        console.log('Invalid coordinates extracted.');
      }
    } else {
      console.log('No coordinates found in Event Comments.');
    }
  }, []);

  const fetchVehicleUnits = useCallback(() => {
    if (userData?.agency) {
      const unitsRef = ref(database, `/UnitLocation/${userData.agency}`);
  
      const unsubscribe = onValue(unitsRef, (snapshot) => {
        const unitsData = snapshot.val();
        if (unitsData) {
          const unitArray = Object.keys(unitsData).map(key => ({
            responderName: key,
            ...unitsData[key],
          }));
          setVehicleUnits(unitArray); // Set the fetched units to state
        }
      });
  
      return () => unsubscribe(); // Clean up the subscription
    }
  }, [userData]);
  

  useEffect(() => {
    fetchVehicleUnits(); // Fetch vehicle units when the component mounts
  }, [fetchVehicleUnits]);

  const getVehicleIcon = (vehicleType) => {
    switch ((vehicleType || "").toLowerCase()) {
      case "engine":
        return FIRE;
      case "chief":
        return HP;
      case "ambulance":
        return EMS;
      default:
        return FIRE;
    }
  };


  const geocodeAddress = async (address, town, docData) => {
    // Check if latitude and longitude are available in the document data
    if (docData?.Latitude && docData?.Longitude) {
      return {
        lat: parseFloat(docData.Latitude),
        lng: parseFloat(docData.Longitude)
      };
    }

    const apiKey = "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM";
    const fullAddress = `${address}, ${town}`;
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: fullAddress,
          key: apiKey,
        },
      }
    );

    if (response.data.status === "OK") {
      const location = response.data.results[0].geometry.location;
      return location;
    } else {
      console.error("Geocoding error:", response.data.status);
      return null;
    }
  };

  const fetchCallinsAndQualifications = async (alarm, agency) => {
    const callinsRef = collection(db, `${agency} Alarms/${alarm.id}/Callins`);
    const callinsSnapshot = await getDocs(callinsRef);
    const callins = await Promise.all(
      callinsSnapshot.docs.map(async (callinDoc) => {
        const callinData = callinDoc.data();
        const qualificationsRef = collection(
          db,
          "Agencies",
          agency,
          "Qualifications"
        );
        const qualificationsSnapshot = await getDocs(qualificationsRef);
        const userQualifications = [];

        qualificationsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.users && data.users.includes(callinData.id)) {
            userQualifications.push(doc.id);
          }
        });

        console.log(
          `Callin User: ${
            callinData.name
          }, Qualifications: ${userQualifications.join(", ")}`
        );

        return {
          ...callinData,
          qualifications: userQualifications,
        };
      })
    );
    return callins;
  };

  const updateDocumentAcknowledgement = async (docId) => {
    try {
      const docRef = doc(db, `${userData.agency} Alarms`, docId);
      await updateDoc(docRef, {
        ACK: true
      });
    } catch (error) {
      console.error("Error updating document acknowledgement:", error);
      toast.error("Failed to update acknowledgement status");
    }
  };

  const fetchAlarms = useCallback(() => {
    if (userData?.agency) {
      const currentTime = new Date();
      const pastTime = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      console.log("Fetching alarms from:", pastTime);

      const q = query(
        collection(db, `${userData.agency} Alarms`),
        where("TimeStamp", ">=", Timestamp.fromDate(pastTime))
      );

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        console.log("Query snapshot size:", querySnapshot.size);
        
        const changes = querySnapshot.docChanges();
        
        if (changes.length > 0) {
          console.log(`Processing ${changes.length} changes`);
          
          for (const change of changes) {
            const data = change.doc.data();
            
            if (!data.RunNumber || data.AlarmStatus === "false") continue;
            
            if (change.type === "added" && !processedDocIds.current.has(change.doc.id) && data.ACK !== true) {
              const audio = new Audio(notificationSound);
              audio.play();

              const address = data.Address;
              const calltype = data.CallType;

              const toastId = toast(
                `A new call has been added to your queue! ${calltype} At Address: ${address}`,
                {
                  position: "top-center",
                  autoClose: false,
                  closeButton: (
                    <button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        toast.dismiss(toastId);
                        audio.pause();
                        audio.currentTime = 0;
                        updateDocumentAcknowledgement(change.doc.id);
                      }}
                    >
                      Acknowledge
                    </button>
                  ),
                  closeOnClick: false,
                }
              );

              processedDocIds.current.add(change.doc.id);
            }
            
            if (change.type === "modified" && selectedAlarm && change.doc.id === selectedAlarm.id) {
              await handleRefresh();
            }
          }
        }
        
        const alarms = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            if (!data.RunNumber || data.AlarmStatus === "false") return null; // Filter out documents
            
            try {
              let location;
              if (data.Latitude && data.Longitude) {
                location = {
                  lat: parseFloat(data.Latitude),
                  lng: parseFloat(data.Longitude)
                };
              } else {
                location = await geocodeAddress(data.Address, data.Town, data);
              }
              
              const timeStamp = data.TimeStamp
                ? data.TimeStamp.toDate()
                : moment(
                    data.Eventtime.split("Dispatch Time: ")[1],
                    "MM-DD-YYYY HH:mm:ss"
                  ).toDate();

              return { ...data, location, id: doc.id, timeStamp };
            } catch (error) {
              console.error('Error processing alarm:', error);
              return null;
            }
          })
        );

        const nonNullAlarms = alarms.filter(alarm => alarm !== null);
        nonNullAlarms.sort((a, b) => b.timeStamp - a.timeStamp);

        console.log("Setting alarmData with filtered alarms:", nonNullAlarms.length);
        setAlarmData(nonNullAlarms);
      });

      return () => unsubscribe();
    }
  }, [userData, selectedAlarm]);

  const setupCallinsListener = (alarm, agency) => {
    const callinsRef = collection(db, `${agency} Alarms/${alarm.id}/Callins`);
    
    let cachedQualifications = null;
    
    const unsubscribe = onSnapshot(callinsRef, async (snapshot) => {
      if (!cachedQualifications) {
        const qualificationsRef = collection(db, "Agencies", agency, "Qualifications");
        const qualificationsSnapshot = await getDocs(qualificationsRef);
        
        cachedQualifications = {};
        qualificationsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.users) {
            data.users.forEach(userId => {
              if (!cachedQualifications[userId]) {
                cachedQualifications[userId] = [];
              }
              cachedQualifications[userId].push(doc.id);
            });
          }
        });
      }
      
      const callinsData = snapshot.docs.map(callinDoc => {
        const callinData = callinDoc.data();
        const userQualifications = cachedQualifications[callinData.id] || [];
        
        return {
          ...callinData,
          qualifications: userQualifications,
        };
      });
      
      setCallins(callinsData);
    });

    return () => unsubscribe();
  };

  const handleCloseAlarm = async () => {
    if (selectedAlarm && userData?.agency) {
      try {
        const alarmRef = doc(db, `${userData.agency} Alarms`, selectedAlarm.id);
        await updateDoc(alarmRef, {
          AlarmStatus: "false"
        });
        toast.success("Alarm closed successfully");
        setIsCloseAlarmModalOpen(false);
        
        setTimeout(() => {
          handleRestart();
        }, 1500);
      } catch (error) {
        console.error("Error closing alarm:", error);
        toast.error("Failed to close alarm");
      }
    }
  };

  const handleToggleMute = (muted) => {
    setIsMuted(muted);
  };

  useEffect(() => {
    if (selectedAlarm?.FRESComments && prevFresComments !== selectedAlarm.FRESComments && !isMuted) {
      notificationAudio.current.play();
      setPrevFresComments(selectedAlarm.FRESComments);
    }
  }, [selectedAlarm?.FRESComments, prevFresComments, isMuted]);

  useEffect(() => {
    const unsubscribe = fetchAlarms();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [fetchAlarms]);

  const fetchPreplan = useCallback(async (alarm) => {
    if (!alarm || !userData?.agency) return;
    
    try {
      const address = alarm.Address;
      console.log('----------------------------------------');
      console.log('🔍 Starting Preplan Search');
      console.log('Current Alarm Address:', address);
      
      if (!address || address.trim() === '') {
        console.log('❌ No valid address to check');
        setPreplanData(null);
        return;
      }
      
      const preplansRef = collection(db, 'Agencies', userData.agency, 'Preplans');
      const snapshot = await getDocs(preplansRef);
      
      const plans = snapshot.docs;
      console.log(`Found ${plans.length} preplans to check`);
      
      const matchingPlan = plans.find(doc => {
        const preplanData = doc.data();
        if (!preplanData.streetAddress || preplanData.streetAddress.trim() === '') {
          console.log('Skipping preplan with no address');
          return false;
        }
        
        const preplanStreet = preplanData.streetAddress.split(',')[0].trim().toUpperCase();
        const alarmAddress = address.trim().toUpperCase();
        
        console.log('\n📍 Checking Preplan:');
        console.log('Raw Preplan Address:', preplanData.streetAddress);
        console.log('Normalized Preplan:', preplanStreet);
        console.log('Normalized Alarm:', alarmAddress);
        
        if (preplanStreet.length > 0 && alarmAddress.length > 0) {
          const hasMatch = alarmAddress.includes(preplanStreet) || preplanStreet.includes(alarmAddress);
          console.log('Match by inclusion?', hasMatch);
          return hasMatch;
        }
        
        return false;
      });

      if (matchingPlan) {
        console.log('\n✅ Found matching preplan!');
        console.log('Matched Address:', matchingPlan.data().streetAddress);
        setPreplanData(matchingPlan.data());
      } else {
        console.log('\n❌ No matching preplan found');
        setPreplanData(null);
      }
      console.log('----------------------------------------');
    } catch (error) {
      console.error('❌ Error fetching preplan:', error);
      toast.error('Error fetching preplan information');
    }
  }, [userData?.agency]);

  useEffect(() => {
    if (selectedAlarm?.Address) {
      console.log('Selected Alarm Data:', {
        Address: selectedAlarm.Address,
        fullData: selectedAlarm
      });
      fetchPreplan(selectedAlarm);
    } else {
      setPreplanData(null);
    }
  }, [selectedAlarm, fetchPreplan]);

  const handleRowClick = async (alarm) => {
    setSelectedAlarm({ ...alarm });
    
    const callins = await fetchCallinsAndQualifications(alarm, userData.agency);
    setCallins(callins);
    
    if (alarm.location) {
      setMapLocation(alarm.location);
    }
    
    if (alarm?.EventComments) {
      extractCallerCoordinates(alarm.EventComments);
    }

    if (userData?.agency && userData?.id) {
      const selectedAlarmRef = ref(database, `/PSAPLink/${userData.id}/SelectedAlarm/${userData.agency}`);
      await set(selectedAlarmRef, {
        alarmId: alarm.id,
        timestamp: serverTimestamp()
      });
    }
  };

  useEffect(() => {
    if (selectedAlarm && userData?.agency) {
      const updateTime = selectedAlarm.TimeStamp.toDate();
      const estTimeUpdate = moment(updateTime).tz("America/New_York");
      const lastUpdateTime = estTimeUpdate.format();
      setLastUpdatedText(`Last updated ${moment(lastUpdateTime).fromNow()}`);
      
      const unsubscribeCallins = setupCallinsListener(
        selectedAlarm,
        userData.agency
      );
      
      const PSAPTimesCollectionRef = collection(
        db,
        `${userData.agency} Alarms`,
        selectedAlarm.id,
        'PSAPTIMES'
      );

      const unsubscribePSAPTimes = onSnapshot(
        query(PSAPTimesCollectionRef, orderBy('TimeStamp', 'desc')),
        (snapshot) => {
          const psaptimesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          
          if (prevPSAPTimesLength.current > 0 && psaptimesData.length > prevPSAPTimesLength.current && !isMuted) {
            console.log('Playing cabin chime...');
            const playPromise = cabinChimeAudio.current.play();
            
            if (playPromise !== undefined) {
              playPromise
                .then(() => {
                  console.log('Cabin chime played successfully');
                })
                .catch(error => {
                  console.error('Error playing cabin chime:', error);
                });
            }
          }
          
          prevPSAPTimesLength.current = psaptimesData.length;
          setPSAPTimes(psaptimesData);
        }
      );

      return () => {
        if (unsubscribeCallins) unsubscribeCallins();
        if (unsubscribePSAPTimes) unsubscribePSAPTimes();
      };
    }
  }, [selectedAlarm, userData?.agency]);

  const getCallCardImage = (callcard) => {
    switch (callcard) {
      case "EMS":
        return EMS;
      case "FIRE":
        return FIRE;
      case "**HIGH PRIORITY**":
        return HP;
      default:
        return null;
    }
  };

  const handleDispatchResponse = async (responseType) => {
    if (selectedAlarm) {
      if (
        window.confirm(
          `Are you sure you want to send "${responseType}" message to Suffolk County Fire Rescue?`
        )
      ) {
        const remarksRef = collection(
          db,
          `${userData.agency} Alarms/${selectedAlarm.id}/Remarks`
        );
        await addDoc(remarksRef, {
          responseType,
          RunNumber: selectedAlarm.RunNumber ?? "",
          CadSequence: selectedAlarm.CadSequence ?? "",
          CommonID: selectedAlarm.CommonID ?? "",
          AgencyIdentifier: selectedAlarm.Agency ?? "",
          tag: userData.tag ?? "",
          VenderID: "Laddersuite",
          timestamp: serverTimestamp(),
          ISOstamp: new Date().toISOString(),
        });
        alert(`${responseType} response sent successfully.`);
      }
    } else {
      alert("No alarm selected.");
    }
  };

  const fetchAgencyLogo = useCallback(async () => {
    if (userData?.agency) {
      try {
        const logoDocRef = doc(db, 'CRM', userData.agency);
        const logoDoc = await getDoc(logoDocRef);
        if (logoDoc.exists() && logoDoc.data().logo) {
          setAgencyLogo(logoDoc.data().logo);
        } else {
          console.log('No logo URL found for agency');
          setAgencyLogo(null);
        }
      } catch (error) {
        console.log('Error fetching logo URL:', error);
        setAgencyLogo(null);
      }
    }
  }, [userData?.agency]);

  useEffect(() => {
    fetchAgencyLogo();
  }, [fetchAgencyLogo]);

  const handleRefresh = async () => {
    if (selectedAlarm && userData?.agency) {
      const alarmDocRef = doc(
        db,
        `${userData.agency} Alarms`,
        selectedAlarm.id
      );
      const alarmDoc = await getDoc(alarmDocRef);
      if (alarmDoc.exists()) {
        const data = alarmDoc.data();
        const callins = await fetchCallinsAndQualifications(
          { ...data, id: alarmDoc.id },
          userData.agency
        );
        console.log("REFRESHING");
        setSelectedAlarm({
          ...data,
          location: selectedAlarm.location,
          id: alarmDoc.id,
        });
        setCallins(callins);
      } else {
        alert("Failed to fetch the updated call data.");
      }
    } else {
      alert("No alarm selected or agency data unavailable.");
    }
  };

  const renderMap = () => {
    try {
      const validAlarms = alarmData.filter(alarm => 
        alarm.location && 
        alarm.location.lat !== "" && 
        alarm.location.lng !== ""
      );

      return (
        <GoogleMapComponent
          location={mapLocation}
          hydrants={hydrantLocations}
          vehicles={vehicleUnits}
          callerLocation={callerLocation}
          alarms={validAlarms}
          onAlarmSelect={setSelectedAlarm}
        />
      );
    } catch (error) {
      console.error('Map error:', error);
      toast.error('Error displaying map. Some locations may be unavailable.', {
        position: "top-right",
        autoClose: 5000,
      });
      return <div className="map-error">Map temporarily unavailable</div>;
    }
  };

  const handleRestart = () => {
    window.location.reload();
  };

  return (
    <div className="home">
      <div className="homeContainer">
        <Navbar onToggleMute={handleToggleMute} />

        <div className="agency-header">
          {agencyLogo && (
            <img 
              src={agencyLogo} 
              alt={`${userData?.agency} logo`} 
              className="agency-logo"
            />
          )}
          <h2>{userData?.agency}</h2>
        </div>

        <div className="content">
          <div className="column callInfo">
            <h2>Call Info</h2>
            <div className="lastUpdatedText">{lastUpdatedText}</div>
            {selectedAlarm && (
              <div>
                <div className="callTypeBox">
                  <img
                    src={getCallCardImage(selectedAlarm.callcard)}
                    alt={selectedAlarm.callcard}
                    className="callTypeIcon"
                    width="35"
                    height="35"
                  />
                  {selectedAlarm.CallType}
                </div>
                <div className="callInfoBox">{selectedAlarm.DispatchCode}</div>
                
               

                <h3>Dispatch Time</h3>
                <div className="callInfoBox">{selectedAlarm.Eventtime}</div>

                {preplanData && (
                  <>
                    {preplanData.dispatchNotes && (
                      <div className="preplan-section">
                        <h3>
                          Preplan Notes
                          <FaExclamationTriangle className="flash-icon" style={{ color: '#FFA500', marginLeft: '10px' }} />
                        </h3>
                        <div className="dispatch-notes-container">
                          <p className="preplan-text">{preplanData.dispatchNotes}</p>
                        </div>
                      </div>
                    )}
                    
                    <div className="preplan-section">
                      <h3>Pre-Plan Information</h3>
                      <div className="preplan-container">
                        {['hazardousMaterials', 'autoMutualAgencies', 'pocReps', 'knoxBoxCodes', 'locationName'].map(key => {
                          if (preplanData[key]) {
                            return (
                              <p key={key} className="preplan-text">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}: </strong>
                                {preplanData[key]}
                              </p>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </>
                )}
               
                <h3>Incident Location</h3>
                <div className="callInfoBox">
                {selectedAlarm.LocationComments}
                <br />
                  {selectedAlarm.Address}
                  <br />
                  {selectedAlarm.Town}
                  <br />
                  {selectedAlarm.CrossStreet}
                </div>
                <h3>Dispatch Comments</h3>
                <div className="callInfoBox">{selectedAlarm.FRESComments}</div>
                <h3>Supplemental Comments/PROQA</h3>
                <div className="callInfoBox">{selectedAlarm.EventComments}</div>
                {PSAPTimes.length > 0 && (
                  <>
                    <h3>PSAP Times</h3>
                    <div className="psapTimesTable">
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Unit</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Time</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {PSAPTimes.map((psapTime) => (
                              <TableRow key={`${psapTime.id}-${psapTime.TimeStamp}`}>
                                <TableCell>{psapTime.UnitId}</TableCell>
                                <TableCell>
                                  {psapTime.UnitStatus === "Transporting" 
                                    ? `${psapTime.UnitStatus} to ${psapTime.UnitDestination || 'Unknown'}`
                                    : psapTime.UnitStatus}
                                </TableCell>
                                <TableCell>
                                  {psapTime.UnitStatusTime
                                    ? moment(psapTime.UnitStatusTime).tz('America/New_York').format('HH:mm:ss')
                                    : 'No Timestamp'}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
                <h3>Caller Information</h3>
                <div className="callInfoBox">{selectedAlarm.CallBack}</div>
              </div>
            )}
          </div>
          <div className="column map">
            <div className="dispatch-tools">
              <h2>Dispatch Tools</h2>
              <div className="button-group">
                <button
                  className="dispatch-button"
                  onClick={() => handleDispatchResponse("Rescue On The Road")}
                >
                  <FaAmbulance className="dispatch-icon" /> Rescue Responding
                </button>
                <button
                  className="dispatch-button"
                  onClick={() => handleDispatchResponse("Fire On The Road")}
                >
                  <FaFireAlt className="dispatch-icon" /> Fire Responding
                </button>
                <button className="dispatch-button" onClick={handleRestart}>
                  <FaSyncAlt className="dispatch-icon" /> Reset
                </button>
                {selectedAlarm && (
                  <button
                    className="dispatch-button"
                    onClick={() => setIsCloseAlarmModalOpen(true)}
                  >
                    <FaTimes className="dispatch-icon" /> Close Alarm
                  </button>
                )}
              </div>
            </div>
            {renderMap()}
          </div>
          <div className="column callQueue">
            <div className="callQueueTop">
              <h2>Call Queue</h2>
              <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 460 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Call Type</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Dispatch Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {alarmData.map((alarm, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(alarm)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <div className="call-type-container">
                            {alarm.callcard === "EMS" ? (
                              <img
                                src={EMS}
                                alt="EMS"
                                className="image"
                                width="35"
                                height="35"
                              />
                            ) : alarm.callcard === "FIRE" ? (
                              <img
                                src={FIRE}
                                alt="Fire"
                                className="image"
                                width="35"
                                height="35"
                              />
                            ) : alarm.callcard === "**HIGH PRIORITY**" ? (
                              <img
                                src={HP}
                                alt="High Priority"
                                className="image"
                                width="35"
                                height="35"
                              />
                            ) : (
                              alarm.callcard
                            )}
                            {alarm.Agency && (
                              <div className="agency-text">{alarm.Agency}</div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{alarm.CallType}</TableCell>
                        <TableCell>{alarm.Address}</TableCell>
                        <TableCell>{alarm.Eventtime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="section-controls">
              <button 
                className="hide-button"
                onClick={() => setIsCallInsHidden(!isCallInsHidden)}
                title={isCallInsHidden ? "Show Call Ins" : "Hide Call Ins"}
              >
                {isCallInsHidden ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
            {!isCallInsHidden && (
              <div className={`callQueueBottom ${isCallInsDetached ? 'detached' : ''}`}>
                <div className="callInsHeader">
                  <h2>Call Ins</h2>
                  <button 
                    className="detach-button"
                    onClick={() => setIsCallInsDetached(!isCallInsDetached)}
                    title={isCallInsDetached ? "Merge Call Ins" : "Detach Call Ins"}
                  >
                    {isCallInsDetached ? <FaArrowLeft /> : <FaArrowRight />}
                  </button>
                </div>
                <TableContainer component={Paper} className="table">
                  <Table sx={{ minWidth: 460 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Callin User</TableCell>
                        <TableCell>Qualifications</TableCell>
                        <TableCell>Response</TableCell>
                        <TableCell>Call In Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedAlarm &&
                        callins &&
                        callins.map((callin, index) => (
                          <TableRow key={index}>
                            <TableCell>{callin.name}</TableCell>
                            <TableCell>
                              {callin.qualifications.join(", ")}
                            </TableCell>
                            <TableCell>{callin.response}</TableCell>
                            <TableCell>
                              {new Date(
                                callin.timestamp.seconds * 1000
                              ).toLocaleTimeString()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
          {isCallInsDetached && (
            <div className="column callIns">
              <div className="callInsHeader">
                <h2>Call Ins</h2>
                <button 
                  className="detach-button"
                  onClick={() => setIsCallInsDetached(false)}
                  title="Merge Call Ins"
                >
                  <FaArrowLeft />
                </button>
              </div>
              <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 460 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Callin User</TableCell>
                      <TableCell>Qualifications</TableCell>
                      <TableCell>Response</TableCell>
                      <TableCell>Call In Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedAlarm &&
                      callins &&
                      callins.map((callin, index) => (
                        <TableRow key={index}>
                          <TableCell>{callin.name}</TableCell>
                          <TableCell>{callin.qualifications.join(", ")}</TableCell>
                          <TableCell>{callin.response}</TableCell>
                          <TableCell>
                            {new Date(callin.timestamp.seconds * 1000).toLocaleTimeString()}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        <div className="footer">Copyright 2025 Ladder Suite LLC      Version 0.1.14</div>
      </div>
      <Dialog
        open={isCloseAlarmModalOpen}
        onClose={() => setIsCloseAlarmModalOpen(false)}
      >
        <DialogTitle>Close Alarm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to close out this Alarm for all users. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCloseAlarmModalOpen(false)}>No</Button>
          <Button onClick={handleCloseAlarm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Home;